import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import ReactTooltip from "react-tooltip"
import { graphql, Link, useStaticQuery } from "gatsby"
import Categories from "../components/categories"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const NosExclusivite = ({ pc }) => {
  const data = useStaticQuery(graphql`
    query ExcluQuery {
      astral: file(relativePath: { eq: "astral_gif.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
      cristal: file(relativePath: { eq: "cristal_gif.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
      doublodesign: file(relativePath: { eq: "doublo-design_gif.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
      doubloML: file(relativePath: { eq: "doublo-ML_gif.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
      doubloCA: file(relativePath: { eq: "doublo-caisse-americaine_gif.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
    }
  `)

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  return (
    <>
      {isMounted ? <ReactTooltip id="services" event="click focus" type="info" effect="solid" globalEventOff="click" /> : null }
      {isMounted ? <ReactTooltip id="exclusivites" type="info" effect="solid" /> : null }
      <div className="columns">
        <div className="column pr-5 has-text-centered">
          <div className="h2 mt-3" style={{ color: "#fff", fontWeight: "bold" }}>
            Nos exclusivités
          </div>
          <div className="nosExclus" style={{ paddingBottom: "3rem" }}>
            <div className="columns is-vcentered is-centered">
              <div className="column">
                <Link to="/cadre-astral/" style={{ display: "block", maxWidth: 320, margin: "0 auto", width: "100%" }}>
                  <div className="h3 has-text-centered">Le cadre astral</div>
                  <GatsbyImage
                    image={getImage(data.astral)}
                    alt="Cadre astral - Encadrement sur mesure"
                    style={{ width: '100%' }}
                  />
                  <br />
                  <button className="button is-small" data-cy="decouvrir-astral" style={{marginTop: '-2.5rem', background: '#dbb136'}}>Découvrir</button>
                </Link>
              </div>
              <div className="column">
                <Link to="/cadre-entre-deux-verres/" style={{ display: "block", maxWidth: 320, margin: "0 auto", width: "100%" }}>
                  <div className="h3 has-text-centered">Le cadre entre 2 verres</div>
                  <GatsbyImage
                    image={getImage(data.cristal)}
                    alt="Cadre cristal - Encadrement sur mesure"
                    style={{ width: "100%" }}
                  />{" "}
                  <br />
                  <button className="button is-small" data-cy="decouvrir-cristal" style={{marginTop: '-2.5rem', background: '#dbb136'}}>Découvrir</button>
                </Link>
              </div>
            </div>

            <div className="columns is-vcentered">
              <div className="column">
                <Link to="/cadre-doublo-design/" style={{ display: "block", maxWidth: 320, margin: "0 auto", width: "100%" }}>
                  <div className="h3 has-text-centered">Doublo design</div>
                  <GatsbyImage
                    image={getImage(data.doublodesign)}
                    alt="Cadre doublo design  - Encadrement sur mesure"
                    style={{ width: "100%" }}
                  />{" "}
                  <br />
                  <button className="button is-small" data-cy="decouvrir-doublo-design" style={{marginTop: '-2.5rem', background: '#dbb136'}}>Découvrir</button>
                </Link>
              </div>

              <div className="column">
                <Link to="/cadre-doublo-caisse-americaine/" style={{ display: "block", maxWidth: 320, margin: "0 auto", width: "100%" }}>
                  <div className="h3 has-text-centered">Doublo caisse américaine</div>
                  <GatsbyImage
                    image={getImage(data.doubloCA)}
                    alt="Cadre doublo caisse américaine - Encadrement sur mesure"
                    style={{ width: "100%" }}
                  />{" "}
                  <br />
                  <button className="button is-small" data-cy="decouvrir-doublo-americ" style={{marginTop: '-2.5rem', background: '#dbb136'}}>Découvrir</button>
                </Link>
              </div>
              <div className="column">
                <Link to="/cadre-doublo-marie-louise/" style={{ display: "block", maxWidth: 320, margin: "0 auto", width: "100%" }}>
                  <div className="h3 has-text-centered">Doublo marie-louise</div>
                  <GatsbyImage
                    image={getImage(data.doubloML)}
                    alt="Cadre doublo marie Louise  - Encadrement sur mesure"
                    style={{ width: "100%" }}
                  />{" "}
                  <br />
                  <button className="button is-small" data-cy="decouvrir-doublo-marie" style={{marginTop: '-2.5rem', background: '#dbb136'}}>Découvrir</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="column has-text-weight-bold pl-5 is-narrow">
          <div style={{ maxWidth: 360 }}>
            <div style={{ background: "#626262", marginTop: "2rem", padding: "1rem", color: "#DDD", borderRight: "7px solid  #dbb136" }}>
              <span style={{ color: "#dbb136", fontSize: "1.3em" }}>Nos services :</span>
              <br />- baguette précoupée
              <br />- cadre coupé assemblé (avec ou sans isorel)
              <br />- cadre complet (verre ou plexiglass avec passe partout)
            </div>
            <div className="h3 is-size-5 mt-3" style={{ color: "#fff", fontWeight: "bold" }}>
              Les types de cadres proposés&nbsp;:
            </div>
            <Categories
              categories={Object.values(pc.categories)}
              pc={{ ...pc, couleur: null, categorie: null, dimension: null, racine: "cadres" }}
              bg={"#626262"}
              homeMenu={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}

NosExclusivite.propTypes = {
  data: PropTypes.object,
  pc: PropTypes.object,
}

export default NosExclusivite
