import React, { useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import SEO from "./../components/seo"
import ChoixDimensions from "../components/choix/choixDimensions"
import NosExclusivite from "../components/nosExclusivites"
import Banner from "../components/banner"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { useDispatch } from "react-redux"
import { cancelPhoto } from "../actions/photos"
import PropTypes from "prop-types"

const Home = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(cancelPhoto())
  }, [])
  const data = useStaticQuery(graphql`
    query HomeQuery {
      cadresPage: sitePage(path: { eq: "/cadres/" }) {
        id
        context {
          sizesMax {
            ba_maxi
            ba_perimetre_maxi
          }
        }
      }
      passePartout: file(relativePath: { eq: "passe-partout.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      multivues: file(relativePath: { eq: "multivues.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      photoEncadree: file(relativePath: { eq: "photo-encadree.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 120, height: 120, quality: 85)
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Encadrement sur mesure"
        description="Cadre et encadrement sur mesure pour photo, peinture, tableau et tous types de sujets. Dans notre vaste atelier d'encadrement, notre équipe d'ébénistes coupent, ajustent et assemblent vos cadres sur mesure."
        keywords={[`Encadrement sur mesure`, `Encadreur`, `Artiste peintre`, `Photographe`, `Galeriste`, `Imprimeur`, `Encadrement de qualité`]}
        canonical={process.env.GATSBY_URL_ACTUEL}
      />
      <div className="hero homeHero">
        <h1 style={{textAlign: "center", margin: 0, padding: 2, background: "#f4e9ca66", color: "#0a0a0a", fontSize: "1.2rem", fontWeight: "bold", borderBottom: 0}}>Encadrement sur mesure</h1>
        <div className="hero-body" style={{ paddingTop: 0 }}>
          <div className="columns is-8 homeServices is-vcentered">
            <div className="column is-3">
              <section className="banner-section mt-5 has-text-centered" style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
                <div className="h2 mt-0 is-size-4">
                  <Link to="/tirage-photo-encadre/">Tirage photo encadrée</Link>
                </div>
                <div>
                  <Link to="/tirage-photo-encadre/">
                    <GatsbyImage image={getImage(data.photoEncadree)} alt="Photo encadrée" style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                  </Link>
                </div>
                <Link to="/tirage-photo-encadre/" className="button is-primary mb-2">
                  Choisir
                </Link>
              </section>
            </div>
            <div className="column is-3 homeServicePP">
              <section className="banner-section">
                <div className="pt-2 h2">
                  <Link to="/passe-partout">Passe partout</Link>
                </div>
                <p className="is-size-6" style={{ marginTop: -15 }}>
                  Découpe numérique à vos mesures
                </p>
                <div className="columns has-text-centered is-centered is-vcentered is-desktop" style={{ marginTop: -20 }}>
                  <div className="column is-12 is-half-desktop is-half-fullhd">
                    <span className="has-text-weight-bold">1&nbsp;ouverture</span>
                    <Link
                      to="/passe-partout/?type=1ouverture"
                      className="is-size-7-desktop is-size-6-widescreen"
                      style={{ paddingBottom: "1.5rem", paddingTop: "1rem" }}
                      rel="noindex"
                    >
                      <div className="columns is-centered mt-3">
                        <GatsbyImage image={getImage(data.passePartout)} alt="Passe partout 1 ouverture" style={{ maxWidth: 100, width: "100%" }} />
                      </div>
                    </Link>
                    <Link to="/passe-partout/?type=1ouverture" className="button is-primary mt-5" rel="noindex">
                      Choisir
                    </Link>
                  </div>
                  <div className="column is-12 is-half-desktop is-half-fullhd">
                    <span className="has-text-weight-bold">Multivues</span>
                    <Link
                      to="/passe-partout/?type=multivues"
                      className="is-size-7-desktop is-size-6-widescreen"
                      style={{ paddingBottom: "1.5rem", paddingTop: "0.5rem" }}
                      rel="noindex"
                    >
                      <div className="columns is-centered mt-3">
                        <GatsbyImage image={getImage(data.multivues)} alt="Passe partout multivues" style={{ maxWidth: 100, width: "100%" }} />
                      </div>
                    </Link>
                    <Link to="/passe-partout/?type=multivues" className="button is-primary mt-5" rel="noindex">
                      Choisir
                    </Link>
                  </div>
                </div>
              </section>
            </div>
            <div className="column is-6 homeServiceCadre">
              <section className="banner-section">
                <h1 className="h2 pt-5 mt-5 is-size-1 mb-0 pb-0" style={{ lineHeight: "2.8rem" }}>
                  <Link to="/cadres/">Cadre sur mesure</Link>
                </h1>
                <small className="mb-5 mt-2 mr-5">avec ou sans passe partout</small>
                <div className="is-size-5">
                  <span style={{ color: "#c7001e" }}>Au prix d&apos;un cadre standard !</span>
                </div>
                <div className="mt-5">
                  <ChoixDimensions
                    pageContext={{
                      categorie: null,
                      dimension: null,
                      couleur: null,
                      racine: "cadres",
                      sizesMax: {
                        ba_maxi: data.cadresPage?.context?.sizesMax?.ba_maxi || 150,
                        ba_perimetre_maxi: data.cadresPage?.context?.sizesMax?.ba_perimetre_maxi || 480,
                      },
                    }}
                    defaultEmpty={true}
                    txtValider="Choisir mon cadre"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <section className="homeBanner">
        <Banner />
      </section>
      <section
        style={{ background: "rgb(141,22,28) linear-gradient(0deg, rgba(91,24,28,1) 0%, rgba(141,22,28,1) 100%)", margin: "0 -32px", padding: "0.5rem 2rem" }}
      >
        <NosExclusivite pc={props.pageContext} />
      </section>
      <div className="container mt-6">
        <p>Les artistes professionnels comme amateurs aiment imaginer lors de la réalisation de leur œuvre ou
        une fois achevée, l’encadrement idéal, celui qui s’accordera à la perfection avec le propos, le mettra
        en valeur. <strong>L’encadrement d’un tableau n’est en effet jamais à prendre à la légère</strong>, il fait partie
        intégrante de l’œuvre, véhicule parfois un message. L’artiste va même jusqu’à jouer avec nos sens
        en peignant un cadre dans le cadre à l’image du célèbre tableau de Pere Borrell del Caso, Fuyant la
        critique, exposé au Musée du Prado à Madrid -1874, huile sur toile-.</p>
        <p>Découvrez auprès de l’entreprise de l’Encadrement sur mesure, un service d’un grand
        professionnalisme grâce auquel vous pourrez obtenir la réalisation d’<strong>encadrements de tableaux sur
        mesure</strong> et exposer ainsi à la vue du plus grand nombre vos plus belles œuvres.</p>
        <h2>Encadrer votre tableau : nos conseils pour respecter votre œuvre</h2>
        <p><strong>L’encadrement de tableaux sur mesure est la meilleure façon de valoriser une œuvre</strong>, en plus
        de l’éclairage, de l’endroit où elle est exposée. La conception sur mesure permet à l’artiste de
        bénéficier d’une liberté maximale dans la réalisation de sa toile. Il est maître de ses envies, de ses
        choix. Il peut en effet demander une matière particulière pour les baguettes de son encadrement de
        tableau, choisir la forme la plus appropriée, celle qui créera un rendu visuel esthétique, ainsi que les
        dimensions. Ces dernières restent essentielles afin d’obtenir un résultat aux finitions extraordinaires.</p>
        <p>De nombreux artistes par ailleurs font le choix de la caisse américaine autrement appelé cadre
        inversé. Très en vogue, cette dernière offre un <strong>encadrement de tableau à la fois élégant et sobre</strong>.
        Son design minimaliste permet de l’intégrer au sein de tous les intérieurs. L’œuvre est mise en valeur
        par un effet de profondeur exceptionnel. Grâce aux vitres de protection, l’œuvre, qu’il s’agisse d’une
        aquarelle, d’une photographie, d’une lithographie, d’une peinture, craindra moins les dégâts du
        temps.</p>
        <h2>Encadrement sur mesure : une offre de service complète d’encadrement pour vos
        tableaux</h2>
        <p>Les équipes d’Encadrement sur mesure développent un service d’encadrement de tableaux
        d’exception. Dès lors que nous recevons toutes les informations concernant l’encadrement de tableau
        que vous souhaitez, nous prenons en charge la réalisation de A à Z. Vous recevez ainsi dans un délai
        de huit jours ouvrés votre tableau magnifié. Vous pouvez également faire le choix d’une livraison
        EXPRESS afin de recevoir votre encadrement de tableau au terme d’un jour ouvré.</p>
        <p>Forts d’une expérience certaine, nous répondons à l’ensemble de vos besoins, satisfaisons toutes
        vos ambitions. Nous proposons de fait une large gamme de baguettes en bois et en aluminium, des
        maries-louises, des caisses américaines, des cadres avec vitrine, des cadres entre deux verres, des
        cadres astraux, une sélection de passe-partout, ainsi que des accessoires qui vous permettront
        d’accrocher comme il se doit votre encadrement de tableau.</p>
        <p>Les équipes d’Encadrement sur mesure sont à votre disposition pour répondre à l’ensemble de vos
        questions et vous aider à <strong>prendre les décisions les plus appropriées pour l’encadrement de vos
        tableaux</strong>. L’offre sur mesure est un gage de qualité, alors n’hésitez plus à faire appel à nos services.</p>
      </div>
    </Layout>
  )
}
Home.propTypes = {
  pageContext: PropTypes.object,
}

export default Home
